<template>
  <q-layout>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Bônus de Afiliados</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Bônus de Afiliados" exact />
      </q-breadcrumbs>
    </div>
    <q-page padding>
      <header-affiliate-bonus :campaignList="campaignList" :channelList="sites"></header-affiliate-bonus>
      <list-affiliate-bonus></list-affiliate-bonus>
      <form-affiliate-bonus :campaigns="campaignList" :sites="sites"></form-affiliate-bonus>
    </q-page>
  </q-layout>
</template>

<script>
import HeaderAffiliateBonus from '../../components/campaign/HeaderAffiliateBonus.vue';
import ListAffiliateBonus from '../../components/campaign/ListAffiliateBonus.vue';
import FormAffiliateBonus from '../../components/campaign/FormAffiliateBonus.vue';

export default {
  components: { HeaderAffiliateBonus, ListAffiliateBonus, FormAffiliateBonus },
  name: 'AffilateBonus',

  created() {
    this.getSites();
    this.getCampaigns();
  },

  data() {
    return {
      sites: [],
      campaignList: []
    };
  },

  methods: {
    async getCampaigns() {
      try {
        const { data, status } = await this.$http.get('/redirect?app=ADVERTISER&path=/api/get/campaign/all');
        if (status === 200) {
          this.campaignList = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async getSites() {
      try {
        const { data, status } = await this.$http.get('/redirect?app=AFFILIATE&path=/api/affiliate/site/filter');
        if (status === 200) {
          this.sites = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    }
  }
};
</script>
