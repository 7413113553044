<template>
  <q-dialog @before-show="prepareDialog" v-model="dialogAffiliate" @hide="clearAll">
    <q-card style="min-width: 1200px">
      <q-form @submit="onSave">
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title> {{ affiliateSelect ? $t('edit') : 'Cadastro de' }} Bônus </q-toolbar-title>
          <q-btn flat round dense icon="mdi-close" @click="onClose" />
        </q-toolbar>
        <q-card-section class="row scroll q-col-gutter-md" style="max-height: 80vh">
          <div class="col-6">
            <q-select
              v-model="form.campaign"
              :label="$t('campaign')"
              outlined
              use-input
              input-debounce="200"
              @filter="filterCampaign"
              :options="campaignFiltered"
              option-label="name"
              option-value="id"
              :readonly="affiliateSelect && form.campaign !== null"
              lazy-rules
              :rules="[(val) => !!val || 'Field is required']"
            />
          </div>

          <div class="col-6">
            <q-select
              :readonly="affiliateSelect && form.site !== null"
              v-model="form.site"
              label="Canal"
              outlined
              use-input
              input-debounce="200"
              @filter="filterChannel"
              :options="channelFiltered"
              option-label="name"
              option-value="id"
              lazy-rules
              :rules="[(val) => !!val || 'Field is required']"
            />
          </div>

          <!-- <div class="col-6">
          <select-request
            v-model="form.site"
            label="Canal"
            outlined
            :displayCloseAll="false"
            endpoint="/redirect?app=AFFILIATE&path=/api/affiliate/site/list"
            option-label="name"
            option-value="id"
            required
            @update="(value) => (form.site = value)"
          />
        </div> -->
          <div class="col-6">
            <!-- <q-input
                            outlined
                            type="text"
                            label="Valor de comissão afiliado"
                            v-model="form.value"
                            lazy-rules
                            :rules="[(val) => !!val || 'Field is required']"
                        /> -->
            <q-field
              outlined
              v-model="form.value"
              label="Valor de comissão afiliado"
              lazy-rules
              :rules="[(val) => !!val || 'Este item é obrigatorio!']"
            >
              <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                <money :id="id" class="q-field__input" :value="value" @input="emitValue" v-show="floatingLabel" />
              </template>
            </q-field>
          </div>
          <div class="col-6">
            <q-select
              v-model="form.type"
              :options="[
                {
                  label: 'Order Price',
                  value: '1'
                },
                {
                  label: '10,19%',
                  value: '2'
                },
                {
                  label: 'Preferência',
                  value: '3'
                },
                {
                  label: 'Exclusividade',
                  value: '4'
                },
                {
                  label: 'Black Friday',
                  value: '5'
                },
                {
                  label: 'Compensação',
                  value: '6'
                },
                {
                  label: 'Outros',
                  value: '7'
                }
              ]"
              label="Tipo de bônus"
              outlined
              lazy-rules
              :rules="[(val) => !!val || 'Field is required']"
            />
          </div>
          <div class="row col-6">
            <div class="col-12">
              <!-- <q-input
                            outlined
                            type="text"
                            label="Valor de margem Afilio"
                            v-model="form.afilio_value"
                            lazy-rules
                            :rules="[(val) => !!val || 'Field is required']"
                        /> -->
              <q-field
                outlined
                v-model="form.afilio_value"
                label="Valor de margem Afilio"
                lazy-rules
                :rules="[(val) => !!val || 'Este item é obrigatorio!']"
              >
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <money :id="id" class="q-field__input" :value="value" @input="emitValue" v-show="floatingLabel" />
                </template>
              </q-field>
            </div>
            <div class="col-12">
              <date-input
                outlined
                default-view="Years"
                :v-model="form.date"
                @update="form.date = $event"
                label="Mês/Ano"
              />
            </div>
          </div>
          <div class="col-6">
            <q-input
              outlined
              type="textarea"
              label="Referência"
              v-model="form.reason"
              lazy-rules
              :rules="[isRequired]"
            />
          </div>
        </q-card-section>
        <q-separator />
        <q-card-actions align="right" class="q-pa-md">
          <q-btn outline :label="$t('cancel')" no-caps padding="sm lg" color="primary" @click="onClose" />
          <q-btn unelevated :label="$t('save')" no-caps padding="sm lg" color="primary" type="submit" />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import AffiliateMixin from '../../mixins/AffiliateMixin';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  components: { DateInput },
  name: 'FormAffiliateBonus',
  mixins: [AffiliateMixin],

  props: {
    sites: [],
    campaigns: []
  },

  // watch: {
  //   affiliateSelect(newValue) {
  //     console.log("affiliateSelect: ", newValue);
  //   },
  // },

  data() {
    return {
      afilio_value: 0,
      form: {
        campaign: null,
        site: null,
        value: null,
        afilio_value: 0,
        type: null,
        date: null,
        reason: null
      },
      campaignFiltered: [],
      channelFiltered: [],
      price: null
    };
  },
  methods: {
    filterCampaign(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.campaignFiltered = this.campaigns;
        this.campaignFiltered = this.campaigns.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterChannel(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.channelFiltered = this.sites;
        this.channelFiltered = this.sites.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    prepareDialog() {
      if (this.affiliateSelect && this.dialogAffiliate) this.setValuesAffiliate();
      else this.clearAll();
    },

    setValuesAffiliate() {
      this.form.value = this.affiliateSelect.value;
      this.form.afilio_value = this.affiliateSelect.afilio_value;
      this.form.reason = this.affiliateSelect.reference;

      this.form.campaign = this.campaigns.find((item) => item.id == this.affiliateSelect.id_campaign);
      this.form.site = this.sites.find((item) => item.id == this.affiliateSelect.id_site);
      this.form.date = this.affiliateSelect.date;
      this.form.type = {
        label: this.affiliateSelect.type_bonus,
        value: this.affiliateSelect.id_type_bonus
      };
    },

    clearAll() {
      this.form = {
        campaign: null,
        site: null,
        value: null,
        afilio_value: null,
        type: null,
        date: null,
        reason: null
      };
    },

    onClose() {
      this.ActionSetDialogAffiliate(false);
    },

    onSave() {
      if (this.affiliateSelect) this.updateBonus(this.affiliateSelect.id_transaction);
      else this.createBonus();
    },
    async getRoles() {
      this.loading_role = true;
      try {
        const { data, status } = await this.$http.get('get/role');
        if (status === 200) this.roles = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.loading_role = false;
      }
    },
    async getProfiles() {
      this.loading_profile = true;
      try {
        const { data, status } = await this.$http.get('get/profile');
        if (status === 200) this.profiles = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.loading_profile = false;
      }
    },

    async createBonus() {
      let formData = {};
      formData.id_campaign = this.form.campaign.id;
      formData.name_campaign = this.form.campaign.name;
      formData.id_affiliates = this.form.campaign.advertiser_id;
      formData.id_site = this.form.site.id;
      formData.id_type_bonus = this.form.type.value;
      formData.value = this.form.value;
      formData.afilio_value = this.form.afilio_value;
      formData.reference = this.form.reason;
      formData.date = this.form.date;

      await this.createAffiliateBonus(formData);
      this.ActionSetDialogAffiliate(false);
    },

    async updateBonus(id) {
      let formData = {};
      formData.id_campaign = this.form.campaign.id;
      formData.id_site = this.form.site.id;
      formData.id_type_bonus = this.form.type.value;
      formData.value = this.form.value;
      formData.afilio_value = this.form.afilio_value;
      formData.date = this.form.date;
      formData.reference = this.form.reason;

      await this.editAffiliateBonus(id, formData);
      this.ActionSetDialogAffiliate(false);
    }
  }
};
</script>

<style></style>
