<template>
  <q-intersection transition="slide-down">
    <q-table
      flat
      bordered
      :data="affiliateBonus"
      row-key="id"
      :columns="localColumns"
      :pagination.sync="pagination"
      :visible-columns="visibleColumns"
      class="sticky-column-table"
    >
      <template v-slot:top-right>
        <q-btn
          outline
          color="grey-3"
          size="12px"
          text-color="grey-8"
          icon-right="archive"
          class="q-mr-sm q-py-xs"
          no-caps
          @click="exportBonus()"
          unelevated
        >
          <q-tooltip> Exportar como CSV</q-tooltip>
        </q-btn>

        <q-select
          v-model="visibleColumns"
          multiple
          outlined
          dense
          options-dense
          display-value="Personalizar Colunas"
          emit-value
          map-options
          :options="localColumns"
          option-value="name"
          options-cover
          style="min-width: 150px"
        >
          <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </template>

      <template v-slot:body-cell-actions="props">
        <q-td :props="props">
          <q-btn
            unelevated
            round
            flat
            icon="mdi-pencil-outline"
            color="primary"
            @click="ActionSetDialogAffiliate({ value: props.row })"
          >
            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
              {{ $t('edit') }}
            </q-tooltip>
          </q-btn>

          <q-btn unelevated round flat icon="mdi-minus-circle" color="red" @click="deleteBonus({ value: props.row })">
            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Excluir </q-tooltip>
          </q-btn>
        </q-td>
      </template>
      <template v-slot:bottom>
        <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
          <div class="row items-center">
            <span class="q-mr-md">Registros por página:</span>
            <q-select
              v-model="pagination.rowsPerPage"
              :options="[10, 20, 50, 100]"
              dense
              @input="onRowsPerPageChange"
              style="max-width: 60px; padding-left: 8px; padding-right: 8px"
            />
            <span>Total: {{ reqPagination.total }}</span>
          </div>
          <q-space />
          <q-pagination
            unelevated
            v-model="currentPage"
            color="primary"
            :max="reqPagination.last_page"
            :max-pages="6"
            boundary-numbers
            direction-links
          />
        </q-toolbar>
      </template>
    </q-table>
  </q-intersection>
</template>

<script>
import AffiliateMixin from '../../mixins/AffiliateMixin';
export default {
  name: 'ListAffiliateBonus',
  mixins: [AffiliateMixin],

  data() {
    return {
      visibleColumns: null,
      localColumns: [
        {
          name: 'indice',
          label: 'ID Campanha',
          align: 'left',
          field: 'id_campaign',
          sortable: true
        },
        {
          name: 'impressoes',
          label: 'Nome Campanha',
          align: 'left',
          field: 'campaing_name',
          sortable: true
        },
        {
          name: 'total',
          label: 'ID Canal',
          align: 'left',
          field: 'id_site',
          sortable: true
        },
        {
          name: 'visitantes_unicos',
          label: 'Nome Canal',
          align: 'left',
          field: 'site_name',
          sortable: true
        },
        {
          name: 'ctr',
          label: 'ID Afiliado',
          align: 'left',
          field: 'id_affiliates',
          sortable: true
        },
        {
          name: 'cadastros_pendentes',
          label: 'Nome Afiliado',
          align: 'left',
          field: 'affiliate_name',
          sortable: true
        },
        {
          name: 'cadastros_aprovados',
          label: 'ID Bônus',
          align: 'left',
          field: 'id_commission_bonus',
          sortable: true
        },
        {
          name: 'lcr',
          label: 'Tipo Bonus',
          align: 'left',
          field: 'type_bonus',
          sortable: true
        },
        {
          name: 'vendas_pendentes',
          label: 'Valor Afiliado',
          align: 'left',
          field: 'value',
          format: this.parseMoney,
          sortable: true
        },
        {
          name: 'afilio_value',
          label: 'Margem Afilio',
          align: 'left',
          field: 'afilio_value',
          format: this.parseMoney,
          sortable: true
        },
        {
          name: 'status',
          label: 'Status',
          align: 'left',
          field: 'status_name',
          sortable: true
        },
        {
          name: 'reference',
          label: 'Referente à',
          align: 'left',
          field: 'reference',
          sortable: true
        },
        {
          name: 'date',
          label: 'Data',
          align: 'left',
          field: 'date',
          sortable: true,
          format: (val) => this.$moment(val).format('L')
        },
        {
          name: 'created_at',
          label: 'Data de Criação',
          align: 'left',
          field: 'created_at',
          sortable: true,
          format: (val) => this.$moment(val).format('LL')
        },
        {
          name: 'validation_date',
          label: 'Data de Validação',
          align: 'left',
          field: 'validation_date',
          sortable: true
        },
        {
          name: 'actions',
          label: 'Ações',
          align: 'left',
          field: 'status',
          sortable: true
        }
      ],
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getAffiliateBonus(`page=${this.currentPage}`, `%26perPage%3D${this.pagination.rowsPerPage}%26`);
      }
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  created() {
    const savedRowsPerPage = localStorage.getItem('rowsPerPage');
    if (savedRowsPerPage) {
      this.pagination.rowsPerPage = parseInt(savedRowsPerPage, 10);
    }

    this.getAffiliateBonus( 'page%3D' + 1, '%26perPage%3D' + this.pagination.rowsPerPage + '%26');
    this.visibleColumns = this.localColumns.map((column) => column.name);
  },

  methods: {
    async exportBonus() {
      let columnsCsv = this.localColumns.filter((item) => this.visibleColumns.includes(item.name));

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=affiliate&path=/api/commission/bonusList/%3FperPage%3D99000%26page%3D1%26' +
            this.filterAffiliateBonus
        );
        if (status === 200 || status === 201) {
          this.exportTableCsv(data.data, columnsCsv, 'affiliate-bonus');
          this.successNotify('Exportado com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    getTotalCommissionValue(value) {
      return value
        .map((item) => parseFloat(item.value.replace('.', '').replace('R$', '')))
        .reduce((a, b) => a + b, 0)
        .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    },
    deleteBonus(data) {
      this.showDialog(
        'Alerta',
        'Tem certeza que deseja apagar o bônus selecionado (ID: ' + data.value.id_commission_bonus + ')?',
        () => this.onOk(data.value.id_transaction),
        this.onCancel
      );
    },
    onOk(id) {
      this.deleteAffiliateBonus(id);
    },
    onRowsPerPageChange(value) {
      this.pagination.rowsPerPage = value;
      localStorage.setItem('rowsPerPage', value);
      this.getAffiliateBonus(`page%3D${this.currentPage}`, `%26perPage%3D${this.pagination.rowsPerPage}%26`);
    }
  }
};
</script>
<style lang="sass">
.sticky-column-table
  max-width: 2600px
  thead tr:last-child th:last-child
    background-color: #fff
  td:last-child
    background-color: #fff
  th:last-child,
  td:last-child
    position: sticky
    right: 0
    z-index: 1
</style>
