<template>
  <div>
    <q-toolbar class="q-pr-none q-mb-md justify-end">
      <!-- <q-input outlined v-model="search" dense type="text" label="Pesquisar">
        <template v-slot:prepend>
          <q-icon name="eva-search-outline" />
        </template>
      </q-input> -->
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Bônus </q-tooltip></q-btn
      >
      <!-- <q-btn
        outline
        color="primary"
        icon-right="archive"
        class="q-mr-sm"
        no-caps
        @click="
          exportTableCsv(
            prepareItemCSV(reports),
            prepareColumnCSV(columns),
            'reports'
          )
        "
        unelevated
      >
        <q-tooltip> {{ $t("export_csv") }} </q-tooltip>
      </q-btn> -->
      <q-btn
        unelevated
        color="primary"
        class="q-px-lg"
        icon="mdi-plus"
        :label="$t('add')"
        no-caps
        @click="ActionSetDialogAffiliate(true)"
      />
    </q-toolbar>
    <q-intersection transition="slide-down" v-show="filter">
      <q-card flat v-if="filter" bordered class="q-mb-sm">
        <q-toolbar>
          <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
          <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
        </q-toolbar>
        <q-card-section class="q-pt-none row q-col-gutter-md">
          <div class="col-6">
            <q-select
              v-model="params.campaignId"
              :label="$t('campaign')"
              outlined
              use-input
              input-debounce="200"
              @filter="filterCampaign"
              :options="campaignFiltered"
              option-label="name"
              option-value="id"
              clearable
            />
          </div>
          <div class="col-6">
            <q-select
              v-model="params.siteId"
              label="Canal"
              outlined
              use-input
              input-debounce="200"
              @filter="filterChannel"
              :options="channelFiltered"
              option-label="name"
              option-value="id"
              clearable
            />
          </div>
          <div class="col-6">
            <date-input
              buttons
              :v-model="params.date"
              outlined
              range
              label="Período"
              clearable
              @update="(value) => (params.date = value)"
            />
          </div>
          <div class="col-6">
            <div class="row q-col-gutter-sm">
              <div class="col">
                <q-select
                  v-model="params.type"
                  use-input
                  input-debounce="200"
                  @filter="filterBonusType"
                  :options="bonusTypeFiltered"
                  label="Tipo de bônus"
                  outlined
                  option-label="name"
                  option-value="id_type_bonus"
                  clearable
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <!-- <div class="col-4">
              <span class="text-weight-medium q-mb-none">Status</span>
              <div class="q-gutter-sm">
                <q-checkbox
                  v-model="params.status"
                  val="1"
                  label="Ativo"
                  color="primary"
                />
                <q-checkbox
                  v-model="params.status"
                  val="0"
                  label="Pendente"
                  color="primary"
                />
                <q-checkbox
                  v-model="params.status"
                  val="2"
                  label="Pausado"
                  color="primary"
                />
                <q-checkbox
                  v-model="params.status"
                  val="3"
                  label="Desativado"
                  color="primary"
                />
              </div>
            </div> -->
          </div>
          <div class="col-12 text-right">
            <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
            <q-btn color="positive" :label="$t('search')" no-caps unelevated class="q-px-md" @click="onFilterBonus" />
          </div>
        </q-card-section>
      </q-card>
    </q-intersection>
  </div>
</template>

<script>
//import SelectRequest from "@/widgets/inputsGlobal/SelectRequest.vue";
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';
import AffiliateMixin from '../../mixins/AffiliateMixin';

export default {
  name: 'HeaderAffiliateBonus',
  components: { DateInput },
  mixins: [AffiliateMixin],

  props: {
    channelList: [],
    campaignList: []
  },

  data() {
    return {
      filter: true,
      params: {
        campaignId: null,
        siteId: null,
        date: '',
        type: null
        //status: [],
      },

      bonusTypeFiltered: [],
      channelFiltered: [],
      campaignFiltered: [],
      bonusTypeList: []
    };
  },

  methods: {
    clearFilter() {
      this.params = {
        campaignId: null,
        siteId: null,
        date: '',
        type: null,
        status: []
      };

      this.ActionSetFilterAffiliateBonus();
      this.getAffiliateBonus();
    },

    async getBonusType() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(`redirect?app=AFFILIATE&path=/api/commission/bonusType`);
        if (status === 200) this.bonusTypeList = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterCampaign(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.campaignFiltered = this.campaignList;
        this.campaignFiltered = this.campaignList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterChannel(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.channelFiltered = this.channelList;
        this.channelFiltered = this.channelList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterBonusType(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.bonusTypeFiltered = this.bonusTypeList;
        this.bonusTypeFiltered = this.bonusTypeList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    onFilterBonus() {
      let filterSelect = [];

      if (this.params.campaignId) filterSelect.push(`id_campaign=${this.params.campaignId.id}`);
      if (this.params.siteId) filterSelect.push(`id_site=${this.params.siteId.id}`);
      if (this.params.type) filterSelect.push(`id_type_bonus=${this.params.type.id_type_bonus}`);
      /* if (this.params.status && this.params.status.length > 0)
        filterSelect.push(`status=${this.params.status}%26`); */

      if (this.params.date) {
        let [from, to] = this.params.date.split(' - ');
        from = this.$moment(from, 'DD/MM/YYYY').format('YYYY-MM-DD');
        to = this.$moment(to, 'DD/MM/YYYY').format('YYYY-MM-DD');
        filterSelect.push(`CreateAt=${from}|${to}%26`);
      }

      // this.params.date = this.$moment(
      //   this.stringToDate(this.params.date, "dd/MM/yyyy", "/")
      // ).format("YYYY-MM-DD");
      // filterSelect.push(`date=${this.params.date}`);

      console.log(filterSelect.join('%26'));

      this.ActionSetFilterAffiliateBonus(filterSelect.join('%26'));
      this.getAffiliateBonus();
    }
  },

  created() {
    this.getBonusType();
  }
};
</script>
